<template>
  <div style="display: inline-block">
    <b-button
      variant="success2"
      class="mr-2 confirm-delivery"
      :disabled="disabled"
      @click="onShowModal()"
      >Store
      <i class="far fa-plus-square"></i>
    </b-button>
    <!-- @hide="modelHide" -->
    <b-modal
      v-model="showModal"
      centered
      no-close-on-backdrop
      scrollable
      title="Store Confirmation"
      id="store_confirmation"
      ref="modal"
    >
      <b-overlay :show="modalloading">
        <p>
          Are you sure to finish the review and change the review status to
          "Stored"? Please confirm the storage quantity. Not confirmed quantity
          will be added to "Rejection Quantity".
        </p>
        <b-row>
          <b-col md="12" class="my-1">
            <b-form-group
              label="Storage Quantity"
              label-for="quantity"
              class="search-flex quantity"
              label-cols-sm="4"
              label-cols-lg="3"
              :class="{
                form_error: submitError && storeData.storage_qty > maxQty
              }"
            >
              <b-input-group>
                <template v-slot:append>
                  <b-input-group-text> / {{ maxQty }} </b-input-group-text>
                </template>
                <b-form-input
                  v-model="storeData.storage_qty"
                  type="number"
                  min="0"
                  :max="maxQty"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button
          variant="primary"
          @click="submit()"
          :disabled="submitDisabled"
        >
          Submit
        </b-button>
        <b-button variant="secondary" @click="close()"> Cancel </b-button>
      </template></b-modal
    >
  </div>
</template>

<script>
import apiBus from '@/common/apiBus/index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';

export default {
  name: 'ArticleReviewStore',

  data() {
    return {
      submitDisabled: false,
      disabled: false,
      modalloading: false,
      showModal: false,
      submitError: false,
      options: {
        logistics: []
      }
    };
  },
  props: [
    'initDataFn',
    'maxQty',
    'storeData',
    'articleInformation',
    'formError'
  ],
  components: {},
  methods: {
    onShowModal() {
      if (
        this.articleInformation.ean_number.model != null &&
        this.articleInformation.ean_number.model != '' &&
        this.articleInformation.origin_country.model != null &&
        this.articleInformation.origin_country.model != ''
      ) {
        this.showModal = true;
        this.submitDisabled = false;
        this.storeData.storage_qty = this.maxQty;
        this.storeData.reject_reason = '';
        this.storeData.reject_note = '';
        this.submitError = false;
        this.storeData.state = null;
      } else {
        this.$emit('update:formError', true);
      }
    },
    submit() {
      var data;
      if (
        this.storeData.storage_qty == 0 ||
        this.storeData.storage_qty > this.maxQty
      ) {
        this.submitError = true;
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'The quantity entered is out of limit.' // 提示内容
        });
        return false;
      }
      if (this.storeData.storage_qty < this.maxQty) {
        this.storeData.state = this.maxQty - this.storeData.storage_qty;
        this.showModal = false;
        return false;
      }
      data = {
        article_review_id: this.storeData.article_review_id,
        storage_qty: this.storeData.storage_qty,
        ean_number: this.articleInformation.ean_number.model,
        origin_country: this.articleInformation.origin_country.model,
        reject_reason: '',
        reject_note: ''
      };
      this.modalloading = true;
      this.submitDisabled = true;
      apiBus.articleReview
        .articleReviewStore(data)
        .then(data => {
          console.log(data);
          this.modalloading = false;
          this.submitDisabled = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
          this.showModal = false;
          this.submitError = false;
          this.initDataFn();
        })
        .catch(error => {
          console.log(error);
          this.modalloading = false;
          this.submitDisabled = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    initialTime(obj, name) {
      obj[name] = null;
    },
    modelHide() {
      if (document.getElementsByClassName('form_error').length > 0) {
        var scroll_top = 0;
        if (document.documentElement && document.documentElement.scrollTop) {
          scroll_top = document.documentElement.scrollTop;
        } else if (document.body) {
          scroll_top = document.body.scrollTop;
        }
        var divTop = document
          .getElementsByClassName('form_error')[0]
          .getBoundingClientRect().top;
        console.log(divTop);
        window.scrollTo(
          0,
          divTop + scroll_top - document.documentElement.clientHeight / 3
        );
      }
    },
    add0(m) {
      return m < 10 ? '0' + m : m;
    },
    format(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      return y + '-' + this.add0(m) + '-' + this.add0(d);
    },
    init() {}
  },

  computed: {},
  watch: {
    formError() {
      this.modelHide();
    }
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped>
.confirm-delivery {
  color: #fff;
}
#void_target .modal-body {
  padding-bottom: 150px;
}
</style>
